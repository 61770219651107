import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import VideoEmbed from './videoEmbed';
import VideoPopup from './videoPopup';

const Style = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-large);

  @media screen and (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`;

const VideoList = ({ videos, popup }) => (
  <Style>
    {videos.map((video) => (
      popup ? (
        <VideoPopup key={video.src} {...video} />
      ) : (
        <VideoEmbed key={video.src || video} src={video.src || video} />
      )))}
  </Style>
);

VideoList.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
    }),
  ])).isRequired,
  popup: PropTypes.bool,
};

VideoList.defaultProps = {
  popup: false,
};

export default VideoList;
